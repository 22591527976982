// extracted by mini-css-extract-plugin
export var accDiv = "privacy-policy-module--accDiv--3dssl";
export var blankLineP = "privacy-policy-module--blankLineP--2jF1g";
export var captionB3 = "privacy-policy-module--captionB3--koexI";
export var captionDiv = "privacy-policy-module--captionDiv--KwFbP";
export var displayB = "privacy-policy-module--displayB--iiy-f";
export var displayDiv = "privacy-policy-module--displayDiv--m1P92";
export var docTemplateDiv = "privacy-policy-module--docTemplateDiv--4Clv1";
export var httpseceuropaeujusticed = "privacy-policy-module--httpseceuropaeujusticed--sqeyX";
export var httpseceuropaeujusticedSpan = "privacy-policy-module--httpseceuropaeujusticedSpan--YZnxd";
export var inShort = "privacy-policy-module--inShort--tiu50";
export var pDiv = "privacy-policy-module--pDiv--HQEbs";
export var pDiv1 = "privacy-policy-module--pDiv1--i0CA4";
export var pDiv14 = "privacy-policy-module--pDiv14--jAilh";
export var pDiv16 = "privacy-policy-module--pDiv16--8I7aG";
export var pDiv32 = "privacy-policy-module--pDiv32--opaRY";
export var privacyPolicyDiv = "privacy-policy-module--privacyPolicyDiv--lIPj8";
export var questionsOrConcerns = "privacy-policy-module--questionsOrConcerns--SqHrU";
export var readingThisPrivacyNoticeWi = "privacy-policy-module--readingThisPrivacyNoticeWi--Ol07A";
export var subtitleDiv = "privacy-policy-module--subtitleDiv--VESZa";
export var subtitleLargeDiv = "privacy-policy-module--subtitleLargeDiv--JjntI";
export var textColumnDiv = "privacy-policy-module--textColumnDiv--iEgCQ";
export var textbodyDiv = "privacy-policy-module--textbodyDiv--Oh8P+";
export var textbodyDiv12 = "privacy-policy-module--textbodyDiv12--eoxKN";
export var textbodyDiv13 = "privacy-policy-module--textbodyDiv13--dSiFd";
export var textbodyDiv14 = "privacy-policy-module--textbodyDiv14--5320o";
export var textbodyDiv19 = "privacy-policy-module--textbodyDiv19--Arv59";
export var textbodyDiv2 = "privacy-policy-module--textbodyDiv2--bJd8M";
export var textbodyDiv23 = "privacy-policy-module--textbodyDiv23--dSL-J";
export var textbodyDiv35 = "privacy-policy-module--textbodyDiv35--vKbxk";
export var textbodyDiv38 = "privacy-policy-module--textbodyDiv38--kQeRJ";
export var visitOurWebsite = "privacy-policy-module--visitOurWebsite--aDnW7";
export var visitOurWebsiteAtUdshealth = "privacy-policy-module--visitOurWebsiteAtUdshealth--JLPkV";